import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { JobFeature } from "../components/job-components"
import SuccessStory from "../components/success-story"

const SuccessStoryHorus = ({ data }) => {
  return (
    <SuccessStory
      title="Streamlining blurring and GDPR compliance for mobile mapping applications"
      customerName="Horus"
      customerLocation="The Netherlands"
      customerDescription="Horus is advancing technologies and pushing boundaries to create visionary imagery solutions and actionable insights to make the world a better & safer place to live & work."
      customerIndustries="Software, Hardware, System Integration"
      link="https://horus.nu/"
      logo=""
      style={{
        background: "url(/images/horus-pano.jpg) no-repeat",
        backgroundPositionY: "center",
        backgroundSize: "cover",
      }}
    >
      <h2 className="my-3">Challenge</h2>
      <p>
        <strong>
          Privacy regulations around the world, like GDPR, LGPD, PDPB & CCPA, require that personal information (e.g.,
          faces and license plates) must be anonymized when processing street-level imagery data.
        </strong>
      </p>
      <p>
        To fulfill these regulatory and market-driven requirements, Horus needed to decide whether:
        <ol>
          <li>developing this in-house or integrating a third-party solution</li>
          <li>running the process on the cloud or on-premise.</li>
        </ol>
      </p>
      <p>
        After an internal evaluation, Horus decided that integrating a third-party vendor for image anonymization is a
        cost-effective, efficient, and high-quality solution for its clients.
      </p>
      <p>
        Considering the massive amounts of imagery data, a cloud-based SaaS solution was not an option for their clients
        due to cloud storage costs, upload bandwidth limitations, and data transfer policies in some countries. Thus,
        Horus opted for on-premise data processing.
      </p>
      <h2 className="my-3">Solution</h2>
      <p>
        Horus & Celantur worked together to launch{" "}
        <a href="https://horus.nu/anonymization-mobile-mapping-street-level-imagery/" target="_blank">
          HORISON automated blurring
        </a>{" "}
        - a tailor-made solution built with Celantur Container, that allows Horus’ customers to anonymize street-level
        imagery.
      </p>

      <figure className="figure">
        <video width="100%" height="100%" className="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0" muted controls>
          <source src="https://horus.nu/wp-content/uploads/2022/12/BlurringV1.3.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <figcaption className="figure-caption">
          HORISON automated blurring workflow. ©HORUS View and Explore B.V.
        </figcaption>
      </figure>
      <p>
        Features:
        <ul>
          <li>Automatically blur faces, bodies, and license plates on panoramas & planar imagery</li>
          <li>Deploy HORISON blurring on-premise for fast, secure, and offline post-processing</li>
          <li>Import and transform imagery in different formats (*.JPEG, *,PGR and *.HRS)</li>
          <li>
            Output data (including *.PGR, *. JPEG, or *.HORUS) can be used directly within your workflows like Cubemap &
            MultiRes
          </li>
        </ul>
      </p>

      <figure className="figure">
        <blockquote className="blockquote">
          <cite>"Horus has been using the Celantur blurring tool for a few months now and we're very happy with the results.
          The tool is incredibly easy to integrate and to use. The ability to quickly and easily blur sensitive
          information has saved us a lot of time and effort in post-processing our data. Overall, we highly recommend
          the Celantur blurring tool to anyone who needs to protect sensitive information in their data."</cite>
        </blockquote>
        <figcaption className="figure-caption"> - Bas Beukers | Director Business Development & Marketing</figcaption>
      </figure>
      
      <figure className="figure">
        <Img fluid={data.horusPano.childImageSharp.fluid} className="rounded my-3" />
        <figcaption className="figure-caption">Horus Mobile Mapping imagery.</figcaption>
      </figure>

      <h2 className="my-3">Celantur's contribution</h2>
      <JobFeature>
        Enabling Horus' global customer base to easily anonymize vast amounts of mobile mapping images, and comply with
        data protection regulations.
      </JobFeature>
      <JobFeature>Enabled Horus to provide more value to their customers.</JobFeature>
      <JobFeature>Protecting privacy of citizens around the world.</JobFeature>
    </SuccessStory>
  )
}

export default SuccessStoryHorus

export const query = graphql`
  query {
    horusPano: file(relativePath: { eq: "horus-pano.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
